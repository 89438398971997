<template>
  <PollEditView disabled />
</template>

<script>
import PollEditView from '@/views/poll/vlm/PollEditViewVlm'

export default {
  name: 'PollViewVlm',
  components: {
    PollEditView
  }
}
</script>
